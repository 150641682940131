<!--
 * @Author: your name
 * @Date: 2021-11-25 11:28:45
 * @LastEditTime: 2023-02-10 15:39:39
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 解决方案 智慧温室
 * @FilePath: \new-website-1123\src\views\programme\house\index.vue
-->
<template>
  <div class="programme-house">
    <div class="banner-box">
      <h-banner :url="banner">
        <div class="banner-content-div">
          <span class="title-span">智慧温室解决方案</span>
          <span class="desc-span"
            >上太智慧温室管理系统从多款传感器（室内空气温度、室内空气湿度、室内光照强度、室外空气温度、室外空气湿度、室外光照强度、风速、风向、</span
          >
          <span class="desc-span"
            >雨雪等）获得环境参数信息，并进行存储、显示、数据管理、分析图表展示。系统内部的智能算法对信息进行实时运算，</span
          >
          <span class="desc-span"
            >通过自动驱动温室的各类设施设备（湿帘、风机、内循环风机、内外遮阳网、侧遮阳网、补光灯、天窗、侧窗等），对温室内温度、湿度、</span
          >
          <span class="desc-span"
            >光照度进行调控，从而提供温室内植物适宜的生长环境，有效提高生长速度和生长质量。</span
          >
        </div>
      </h-banner>
    </div>
    <!-- 配置 -->
    <div></div>
    <h-config class="opacity-page scale-page sticky-page"></h-config>
    <!-- 功能介绍 -->
    <h-swiper
      class="opacity-page house-function"
      :title="{
        text: '功能',
        color: '介绍',
      }"
      :dataList="sceneList"
      v-if="sceneList.length > 0"
    >
      <template v-slot:desc>
        远程管理功能、视频监控与防入侵功能、自动备灾功能、灌溉联动功能
      </template>
    </h-swiper>
    <!-- 架构 -->
    <h-frameWork
      class="house-frameWork opacity-page scale-page"
      :data-list="frameworkList"
      :url="frameworkUrl"
    >
      <span slot="label" class="h-label">智慧农业温室</span>
    </h-frameWork>

    <!-- 设备 -->
    <h-device
      class="opacity-page w-device"
      :title="{
        text: '关联硬件',
        color: '设备',
      }"
      :device-list="deviceList"
      :active="deviceActive"
      @change="deviceActive = $event"
    >
      <img :src="currentDevice.cover" alt="" />
    </h-device>

    <m-device class="m-device" :list="deviceList"></m-device>
  </div>
</template>

<script>
import hBanner from "../components/banner.vue";
import hConfig from "./config.vue";
import hSwiper from "../components/swiper.vue";
import hFrameWork from "../components/framework.vue";
import hDevice from "../components/device.vue";
import mDevice from "../components/deviceMobile.vue";
import {getNesViewApi} from '@/api/index.js'
import {getBannerList}from '@/utils/getBanner.js'
export default {
  name: "programme-house",
  components: {
    hBanner,
    hConfig,
    hSwiper,
    hFrameWork,
    hDevice,
    mDevice,
  },
  data() {
    return {
      banner: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/house/banner.png",
      sceneList: [],
      frameworkUrl: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/house/framework.png",
      frameworkList: [
        {
          key: 1,
          label: "物联网平台",
          desc: "自动上传、历史记录、数据分析、智能预警",
        },
        {
          key: 2,
          label: "环境监测",
          desc: "空气温湿度、光照度、二氧化碳",
        },
        {
          key: 3,
          label: "监控系统",
          desc: "空气温湿度、光照度、二氧化碳",
        },
        {
          key: 4,
          label: "智能联动",
          desc: "补光系统、遮光系统、通风系统、灌溉系统",
        },
      ],
      deviceList: [
        {
          title: "智能水肥机",
          desc: "通过云平台实时远程监控系统状态、远程操作系统、远程调整灌溉计划与配置",
          key: 1,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg8.png",
          path:'/special/SF101'
        },
        {
          title: "光伏自动气象测报站ST-X101",
          desc: "光伏自动气象测报站可监测空气温湿度、风速、风向、气压、雨量、光照强度、总辐射量等常规气象要素，可在无人值守的恶劣环境下全天候全自动运行，将所测数据通过4G/5G网络传输到物联网平台存储和分析。系统利用太阳能供电，无需另外接电。",
          key: 2,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg6.png",
          path:'/special/x101'
        },
        {
          title: "太阳能墒情仪ST-SQ101",
          desc: "采用介电常数原理监测土壤水分含量以及温度状态进行动态观测，并可利用4G/5G技术，将所测数据上报到远程联物网平台。整体可以检测3层~5层或定制层数土壤温湿度状态；可以快速、全面的了解土壤墒情信息，科学地制定灌溉计划与抗旱救灾方案。",
          key: 3,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg7.png",
          path:'/special/SQ101'
        },
      ],
      deviceActive: 1,
    };
  },
  created() {
    this.getBanner()
  },
  mounted() {
    this.addView()
  },
  computed: {
    currentDevice() {
      return this.deviceList.filter((item) => item.key == this.deviceActive)[0];
    },
  },
  methods: {
    getBanner(){
      getBannerList('ZHWJS001Ban004').then(res=>{
        this.sceneList = res
      })
    },
    addView(){
      getNesViewApi({type:2,pid:"043ad33f5c0942f8a5a15ec10135dd24"})
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-box {
  overflow: hidden;
  // height: calc(100vh - 80px);
  width: 100%;
  position: relative;
  .m-device {
    display: none;
  }
  .banner-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    padding-bottom: 200px;
    .title-span {
      font-size: 54px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    .desc-span {
      font-size: 22px;
      font-weight: 400;
      line-height: 40px;
    }

    @media screen and (max-width: 1400px) {
      .title-span {
        font-size: 44px;
      }
      .desc-span {
        font-size: 18px;
      }
    }
  }
}
.house-frameWork {
  &::v-deep {
    &.pests-framework .content-div .img-div {
      height: 700px;
      padding: 0;
      img {
        object-fit: cover;
      }
    }
  }
  .h-label {
    display: block;
    color: #fff;
    background: $color-active;
    height: 68px;
    line-height: 64px;
    border-radius: 34px;
    padding: 0 50px;
    font-size: 40px;
  }
}

.m-device {
  display: none;
}

@media screen and (max-width: $mobile-width) {
  .banner-box {
    overflow: hidden;
    width: 100%;
    ::v-deep {
      .programme-banner img {
        width: 150%;
      }
    }
    .banner-content-div {
      text-align: center;
      padding-bottom: 5%;
      width: 86%;
      .title-span {
        font-size: 0.49rem;
        font-weight: bold;
        margin-bottom: 0.27rem;
      }
      .desc-span {
        font-size: 0.2rem;
        font-weight: 400;
        line-height: 0.36rem;
      }
      .banner-ul {
        margin-top: 10%;
        .banner-li {
          .icon-span {
            width: 0.36rem;
            height: 0.36rem;
            font-size: 0.36rem;
          }
          .label-span {
            margin-top: 0.18rem;
            font-size: 0.15rem;
          }
        }
      }
    }
  }

  .house-frameWork {
    margin-top: 40px;
    &::v-deep {
      &.pests-framework .content-div {
        width: 100%;
        .img-div {
          height: 5.45rem;
        }
      }
    }
    .h-label {
      height: 0.58rem;
      line-height: 0.58rem;
      border-radius: 0.29rem;
      padding: 0 0.36rem;
      font-size: 0.27rem;
    }
  }

  .w-device {
    display: none;
  }
  .m-device {
    display: block;
  }
}
</style>
