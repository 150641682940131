<!--
 * @Author: your name
 * @Date: 2021-11-25 16:45:41
 * @LastEditTime: 2021-12-24 10:43:38
 * @LastEditors: Please set LastEditors
 * @Description: 温室配置
 * @FilePath: \new-website-1123\src\views\programme\house\config.vue
-->
<template>
  <div class="house-config">
    <p-title>
      智慧温室
      <template v-slot:color>配置</template>
    </p-title>
    <div class="content-div config-list-1">
      <p-icon v-for="item in dataList" :key="item.key" :icon="item.icon">
        <template v-slot:text1>{{ item.text1 }}</template>
        <!--  -->
        <template v-slot:text2>
          <span v-if="!item.text2" style="display: block"></span>
          <span v-else>{{ item.text2 }}</span>
        </template>
      </p-icon>
    </div>
    <div class="content-div config-list-2">
      <p-icon v-for="item in data2List" :key="item.key" :icon="item.icon">
        <template v-slot:text1>{{ item.text1 }}</template>
        <!--  -->
        <template v-slot:text2>
          <span v-if="!item.text2" style="display: block"></span>
          <span v-else>{{ item.text2 }}</span>
        </template>
      </p-icon>
    </div>
  </div>
</template>

<script>
import pTitle from "../components/title.vue";
import pIcon from "../components/iconMsg.vue";
export default {
  name: "house-config",
  components: {
    pTitle,
    pIcon,
  },
  data() {
    return {
      dataList: [
        {
          key: 1,
          icon: "iconguangzhaoduchuanganqi",
          text1: "光照度传感器",
          text2: "",
        },
        {
          key: 2,
          icon: "iconkongqiwenshiduchuanganqi",
          text1: "空气温湿度",
          text2: "传感器",
        },
        {
          key: 3,
          icon: "iconmiaoqingjianceyi",
          text1: "苗情检测仪",
          text2: "",
        },
        {
          key: 4,
          icon: "iconneiwaizheyangwang",
          text1: "内外遮阳网",
          text2: "",
        },
        {
          key: 5,
          icon: "iconshilian",
          text1: "湿帘",
          text2: "",
        },
        {
          key: 6,
          icon: "iconfengji",
          text1: "风机",
          text2: "",
        },
        {
          key: 7,
          icon: "iconturangchuanganqi",
          text1: "土壤传感器",
          text2: "",
        },
        {
          key: 8,
          icon: "iconbuguangdeng",
          text1: "补光灯",
          text2: "",
        },
      ],
      data2List: [
        {
          key: 9,
          icon: "iconfengxiang",
          text1: "风向",
          text2: "",
        },
        {
          key: 10,
          icon: "iconfengsu",
          text1: "风速",
          text2: "",
        },
        {
          key: 11,
          icon: "iconkaichuangdianji",
          text1: "开窗电机",
          text2: "",
        },
        {
          key: 12,
          icon: "iconjuanmodianji",
          text1: "卷膜电机",
          text2: "",
        },
        {
          key: 13,
          icon: "iconneixunhuanfengji",
          text1: "内循环风机",
          text2: "",
        },
        {
          key: 14,
          icon: "iconturangPHzhichuanganqi",
          text1: "土壤PH值",
          text2: "传感器",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.cscroll();
    this.initList2Style();
    window.addEventListener("scroll", this.cscroll, false);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.cscroll, false);
  },
  methods: {
    /**
     * @description: 监听滚动事件
     */
    cscroll() {
      let div = document.querySelector(".house-config");
      let prev = div.previousElementSibling;
      let chaTop = div.offsetTop - prev.offsetTop;
      if (chaTop > 150) {
        chaTop = chaTop - 150;
        let hHalf = (div.clientHeight - 62 - 60) / 2 / 3;
        let rate = chaTop / hHalf;
        let div1 = document.querySelector(".config-list-1");
        let div2 = document.querySelector(".config-list-2");
        if (rate < 1) {
          div1.style.opacity = rate < 1 ? 1 - rate : 0;
          div2.style.zIndex = -1;
          if (rate > 0.8) {
            div2.style.opacity = 0.2;
          } else {
            div2.style.opacity = 0;
          }
        } else if (rate < 2.8) {
          div1.style.opacity = 0;
          div2.style.opacity = rate - 1 + 0.2;
          div2.style.zIndex = 1;
        }
      }
    },
    /**
     * @description: 修改list2的位置
     */
    initList2Style() {
      let div2 = document.querySelector(".config-list-2");
      if (div2 && window.innerWidth > 800) {
        let top = div2.clientHeight;
        div2.style.top = `-${top}px`;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.house-config {
  padding-top: 120px;
  width: 1200px;
  margin: auto;
  // height: 100vh;
  padding-bottom: 50px;
  position: sticky;
  top: 0;
  .content-div {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    &.config-list-1 {
      margin-top: 80px;
    }
    &.config-list-2 {
      opacity: 0;
      position: relative;
      z-index: -1;
    }
  }
}
@media screen and (max-height: 800px) {
  .house-config {
    padding-top: 80px;
    .content-div {
      &.config-list-1 {
        margin-top: 60px;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .house-config {
    padding-top: 0.73rem;
    width: 100%;
    margin: auto;
    // height: 100vh;
    padding-bottom: 0.36rem;
    position: relative;
    top: 0;
    .content-div {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      &.config-list-1 {
        margin-top: 0.64rem;
      }
      &.config-list-2 {
        opacity: 1;
        position: relative;
        z-index: 1;
      }
    }
  }
}
</style>
